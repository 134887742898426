import { basicGet, basicPost } from '../../../../../api/accordanceApi';
import { axiosDefaultConfig } from '../../../../../api/axiosConfig';
import * as config from '../../../../../api/config.json';

export const filterAccordanceList = (list: any[], state: string, draggable: boolean) => {
  return list
    .filter(item => {
      return item.accordanceState === parseInt(state) || (item.accordanceState === 5 && state === '1');
    })
    .map(c => {
      return { id: c.id.toString(), key: c.id, draggable, accordance: c };
    });
};

export const newDataAfterDrag = (data, dragEndData) => {
  const { cardId, sourceLaneId, targetLaneId, cardDetails } = dragEndData;
  const card = { ...cardDetails, draggable: false };
  const newLanes = data.lanes.map(item => {
    if (item.id === targetLaneId) {
      const newItem = { ...item };
      newItem.cards = [...newItem.cards, card];
      return newItem;
    }
    if (item.id === sourceLaneId) {
      const newItem = { ...item };
      newItem.cards = newItem.cards.filter(card => card.id !== cardId);
      return newItem;
    } else {
      return item;
    }
  });
  return { lanes: newLanes };
};

export const sortDesc = (a: number, b: number) => {
  return b - a;
};

export const parseYearsList = (list: number[]) => {
  return list
    .filter(year => year !== new Date().getFullYear())
    .sort((a, b) => {
      return sortDesc(a, b);
    })
    .map((item, index) => {
      return { value: item.toString(), label: item.toString(), id: index + 2 };
    });
};

export const getInitialData = (t, activeLaneStyle) => {
  return {
    lanes: [
      {
        key: 1,
        id: '1',
        title: t('pending_accordance'),
        cards: [],
      },
      {
        key: 2,
        id: '2',
        title: t('active_accordance'),
        cards: [],
        style: activeLaneStyle,
      },
      {
        key: 3,
        id: '3',
        title: t('accomplished_accordance'),
        cards: [],
      },
      {
        key: 4,
        id: '4',
        title: t('cancelled_accordance'),
        cards: [],
      },
    ],
  };
};

export const translateLaneTitle = (lane, t) => {
  switch (lane.id) {
    case '0':
      lane.title = `${t('draft_accordance')} (${lane.cards.length})`;
      return lane;
    case '1':
      lane.title = `${t('pending_accordance')} (${lane.cards.length})`;
      return lane;
    case '2':
      lane.title = `${t('active_accordance')} (${lane.cards.length})`;
      return lane;
    case '3':
      lane.title = `${t('accomplished_accordance')} (${lane.cards.length})`;
      return lane;
    case '4':
      lane.title = `${t('cancelled_accordance')} (${lane.cards.length})`;
      return lane;
    default:
      return lane;
  }
};

export const getEvents = (id) => {
  let url = config.GET_ACCORDANCES_EVENTS;
  url = url.replace('{idAccordances}', id.toString());
  return new Promise<any[]>(async (resolve, reject) => {
    basicGet(url, resolve, reject);
  })
}

export const getValidity = (idAccordance, idProject) => {
  let url = config.GET_ACCORDANCES_VALIDITY;
  url = url.replace('{accordancesID}', idAccordance.toString());
  url = url.replace('{projectId}', idProject.toString());
  return new Promise<any[]>(async (resolve, reject) => {
    basicGet(url, resolve, reject);
  })
}

export const newEvent = (data: any) => {
  const url = config.POST_ACCORDANCES_EVENT;
  return new Promise(async (resolve, reject) => {
    basicPost(url, resolve, reject, data);
  });
};

export const deleteEvent = (eventId: number) => {
  const url = config.DELETE_ACCORDANCES_EVENT.replace('{id}', eventId.toString());
  return new Promise(async (resolve, reject) => {
    try {
      await axiosDefaultConfig.delete(url);
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};