import React, { useEffect, useState } from 'react';
import styles from './SummaryIndicatorTable.module.scss';
import {
  StyledTableHeader,
  StyledTableRowHeader,
  StyledTableCellHeader,
  StyledTableCell,
} from '../../PorfolioTableShared.Styles';
import { Table, TableBody, TableContainer, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { formatedValueProperty } from '../../../../services/numberUtil';
import {
  tooltipTitle,
  translateMultipleText,
  renderDateOrSlash,
  isPercentageFieldType,
} from '../../../../services/stringUtil';
import { PmrClassificationComponent } from '../../../Util/PmrClassification/PmrClassification';
import { PmrPrintValue } from '../../../Util/PmrPrintValue/PmrPrintValue';
import NumberFormat from 'react-number-format';
import { StyledTableRow } from '../../PortfolioSimpleIndicatorTable/PortfolioSimpleIndicatorTable.Styles';
import { TcdiClassificationComponent } from '../../../Util/TcdiClassification/TcdiClassification';
import PaginationTable from '../../PaginationTable/PaginationTable';
import { Pagination } from '@material-ui/lab';
import usePaginationSort from '../../../../hooks/usePaginationSort';
import { ProjectsListSkeleton } from '../../../LoadersSkeletons/ProjectsListSkeleton/ProjectsListSkeleton';
import { ExcelDownloadButton } from '../../../Util/ExcelDownloadButton/ExcelDownloadButton';
import useDataNormalize from '../../../../hooks/useDataNormalize';
import { useSelector } from 'react-redux';
import Project from '../../../../models/Project';
import { CriteriaType } from '../../../../models/PaginationIndicator';
import './SummaryIndicatorTable.scss';
import { Tooltip } from '@material-ui/core';
import { FilterTables } from '../../../Filter/FilterTables/FilterTables';

const BlackTooltip = withStyles({
  tooltip: {
    //   color: '$ci-color-greyscale-90',
    backgroundColor: '#101319',
    fontSize: '16px',
    fontFamily: 'Rubik',
    lineHeight: '20px',
    padding: '8px 12px',
    borderRadius: '8px',
  },
  arrow: {
    fontSize: 20,
    color: '#4A4A4A',
    '&::before': {
      backgroundColor: '#101319',
    },
  },
})(Tooltip);

interface ReceivedProps {
  projects: any[];
  tableInfo: any;
  bntFilterReset?: boolean;
  type?: string;
}

type Props = ReceivedProps;
export const SummaryIndicatorTable = (props: Props) => {
  const { container, sortIcon } = styles;
  const { t } = useTranslation();
  const history = useHistory();

  const { projects, tableInfo, bntFilterReset, type } = props;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setisLoading] = React.useState(true);

  const [dataFilter, setDataFilter] = useState<any>(projects);

  useEffect(() => {
    setDataFilter(projects);
  }, [projects]);

  // Download Excel
  const headers = projects.length > 0 ? tableInfo.map(x => x.tableHeader) : [];
  const selectedKeys = projects.length > 0 ? tableInfo.map(x => x.rootProperty) : [];
  const selectKeyLon = [
    'code',
    'loanNumber',
    'name',
    'instrument',
    'approvalDate',
    'lastExtensionApprovedDate',
    'operationApprovedAmount',
    'realizedDisbursementAmount',
    'percentRealizedDisbursement',
    'pendingDisbursementAmount',
    'overallStage',
    'pmrValue',
  ];
  const indicatorProjects = useSelector<any, Project[]>(state => state.indicatorProjects);

  const newValueDataFilter = [
    'name',
    'operationApprovedAmount',
    'realizedDisbursementAmount',
    'percentRealizedDisbursement',
    'pendingDisbursementAmount',
  ];

  let data = useDataNormalize(headers, type === 'LON' ? selectKeyLon : selectedKeys, dataFilter);

  const handleClick = projectdIdSelected => {
    if (!projectdIdSelected) {
      return;
    }
    history.push(`/projectdetail?id=${projectdIdSelected}`);
  };

  const sortTable = (keyToSort, fieldType) => {
    let criteriaType: CriteriaType;
    switch (fieldType) {
      case 'numeric':
      case 'money':
      case 'percentage':
      case 'no_round_two_decimal':
        criteriaType = CriteriaType.number;
        break;
      case 'date':
        criteriaType = CriteriaType.date;
        break;

      default:
        criteriaType = CriteriaType.string;
        break;
    }
    handleSort(keyToSort, criteriaType);
  };

  const { handlePaginationChange, handleSort, currentList, pagesCount, currentPage } = usePaginationSort({
    projects: dataFilter,
    defaultSortCriteria: 'title',
    pageCount: rowsPerPage,
  });

  useEffect(() => {
    setisLoading(true);
    if (currentList && currentList.length > 0) {
      setTimeout(() => {
        setisLoading(false);
      }, 2000);
    }
  }, [currentList]);

  const renderingColumnId = (row, project) => {
    if (row === 'project') {
      return (
        <BlackTooltip title={t('go_to_project_info')} arrow>
          <Link className="link" to={`/projectdetail?id=${project.id}`}>
            {project.code}
          </Link>
        </BlackTooltip>
      );
    } else {
      return (
        <BlackTooltip title={t(project.title)} arrow>
          <span className="name-project-nowrap">{project.title}</span>
        </BlackTooltip>
      );
    }
  };

  return (
    <div className={container}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <ExcelDownloadButton headers={headers} selectedKeys={selectedKeys} dataExcel={data} />
        <FilterTables
          projects={projects}
          keysFilter={type === 'LON' ? selectKeyLon : selectedKeys}
          fnDataFilter={setDataFilter}
          disabledNumber={currentPage}
          indicatorKey={'PROJECTS_SUMMARY_PORTFOLIO'}
          filterBtn={bntFilterReset}
        />
      </div>

      <TableContainer>
        {isLoading ? (
          <ProjectsListSkeleton />
        ) : (
          <div className="border-table">
            <Table stickyHeader style={{ tableLayout: 'auto' }} size="small">
              <StyledTableHeader>
                <StyledTableRowHeader>
                  {tableInfo.map(th => (
                    <StyledTableCellHeader
                      key={th.tableHeader}
                      align={th.headerAlign}
                      onClick={() => sortTable(th.rootProperty, th.fieldType)}
                      style={th.headerCellStyle ? th.headerCellStyle : {}}
                    >
                      <div className="title-header">
                        <div className="title-name">{translateMultipleText(th.tableHeader, t)}</div>
                        <div className="icon">
                          <i className={sortIcon}></i>
                        </div>
                      </div>
                    </StyledTableCellHeader>
                  ))}

                  {/* <StyledTableCellHeader align="right" style={{ border: 'none' }} /> */}
                </StyledTableRowHeader>
              </StyledTableHeader>
              <TableBody>
                {dataFilter?.length === 0
                  ? dataFilter?.map((project, index) => (
                      <StyledTableRow key={project.id}>
                        {!currentList?.every(data => newValueDataFilter.some(p => data[p])) ? (
                          tableInfo.map(val => {
                            return (
                              <StyledTableCell key={val.tableHeader} style={{}} align={val.cellAlign}>
                                {val.customCellContent
                                  ? renderCustomCellContent(val.customCellContent, project)
                                  : val.fieldType
                                  ? dataByFieldType(val, project)
                                  : val.tableHeader === 'project' || val.tableHeader === 'name'
                                  ? renderingColumnId(val.tableHeader, project)
                                  : project[val.rootProperty]}
                              </StyledTableCell>
                            );
                          })
                        ) : (
                          <>
                            <StyledTableCell>
                              <BlackTooltip title={t('go_to_project_info')} arrow>
                                <Link className="link" to={`/projectdetail?id=${project.id}`}>
                                  {project.code}
                                </Link>
                              </BlackTooltip>
                            </StyledTableCell>
                            <StyledTableCell>{project.loanNumber}</StyledTableCell>
                            <StyledTableCell>
                              <BlackTooltip title={t(project.name)} arrow>
                                <span className="name-project-nowrap">{project.name}</span>
                              </BlackTooltip>
                            </StyledTableCell>
                            <StyledTableCell>{project.instrument}</StyledTableCell>
                            <StyledTableCell>{renderDateOrSlash(project.approvalDate)}</StyledTableCell>
                            <StyledTableCell>{renderDateOrSlash(project.lastExtensionApprovedDate)}</StyledTableCell>
                            <StyledTableCell>
                              <NumberFormat
                                value={formatedValueProperty('money', project.operationApprovedAmount)}
                                displayType={'text'}
                                thousandSeparator={','}
                                decimalSeparator={'.'}
                                suffix={isPercentageFieldType('money')}
                              />
                            </StyledTableCell>
                            <StyledTableCell>
                              <NumberFormat
                                value={formatedValueProperty('money', project.realizedDisbursementAmount)}
                                displayType={'text'}
                                thousandSeparator={','}
                                decimalSeparator={'.'}
                                suffix={isPercentageFieldType('money')}
                              />
                            </StyledTableCell>
                            <StyledTableCell>
                              <NumberFormat
                                value={formatedValueProperty('percentage', project.percentRealizedDisbursement)}
                                displayType={'text'}
                                thousandSeparator={','}
                                decimalSeparator={'.'}
                                suffix={isPercentageFieldType('percentage')}
                              />
                            </StyledTableCell>
                            <StyledTableCell>
                              <NumberFormat
                                value={formatedValueProperty('money', project.pendingDisbursementAmount)}
                                displayType={'text'}
                                thousandSeparator={','}
                                decimalSeparator={'.'}
                                suffix={isPercentageFieldType('money')}
                              />
                            </StyledTableCell>
                            <StyledTableCell>{t(project.overallStage)}</StyledTableCell>
                            <StyledTableCell>
                              <PmrClassificationComponent classificationColor={parseInt(project.pmrClassification)} />
                              <PmrPrintValue
                                pmrClassi={parseInt(project.pmrClassification)}
                                pmrValue={parseFloat(project.pmrValue)}
                              />
                            </StyledTableCell>
                          </>
                        )}
                        {/* {tableInfo.map(val => {
                        return (
                          <StyledTableCell key={val.tableHeader} style={{}} align={val.cellAlign}>
                            {val.customCellContent
                              ? renderCustomCellContent(val.customCellContent, project)
                              : val.fieldType
                              ? dataByFieldType(val, project)
                              : val.tableHeader === 'project' || val.tableHeader === 'name'
                              ? renderingColumnId(val.tableHeader, project)
                              : project[val.rootProperty]}
                          </StyledTableCell>
                        );
                      })} */}

                        {/* <StyledTableCell align="left" style={{ borderRadius: '0 6px  6px 0', border: 'none' }}>
                        <BidTooltip
                          title={tooltipTitle(t, 'project_info')}
                          placement="bottom-start"
                          enterDelay={500}
                          leaveDelay={200}
                        >
                          <span className="fw" />
                        </BidTooltip>
                      </StyledTableCell> */}
                      </StyledTableRow>
                    ))
                  : currentList?.map((project, index) => (
                      <StyledTableRow key={project.id}>
                        {!currentList?.every(data => newValueDataFilter.some(p => data[p])) ? (
                          tableInfo.map(val => {
                            return (
                              <StyledTableCell key={val.tableHeader} style={{}} align={val.cellAlign}>
                                {val.customCellContent
                                  ? renderCustomCellContent(val.customCellContent, project)
                                  : val.fieldType
                                  ? dataByFieldType(val, project)
                                  : val.tableHeader === 'project' || val.tableHeader === 'name'
                                  ? renderingColumnId(val.tableHeader, project)
                                  : project[val.rootProperty]}
                              </StyledTableCell>
                            );
                          })
                        ) : (
                          <>
                            <StyledTableCell>
                              <BlackTooltip title={t('go_to_project_info')} arrow>
                                <Link className="link" to={`/projectdetail?id=${project.id}`}>
                                  {project.code}
                                </Link>
                              </BlackTooltip>
                            </StyledTableCell>
                            <StyledTableCell>{project.loanNumber}</StyledTableCell>
                            <StyledTableCell>
                              <BlackTooltip title={t(project.name)} arrow>
                                <span className="name-project-nowrap">{project.name}</span>
                              </BlackTooltip>
                            </StyledTableCell>
                            <StyledTableCell>{project.instrument}</StyledTableCell>
                            <StyledTableCell>{renderDateOrSlash(project.approvalDate)}</StyledTableCell>
                            <StyledTableCell>{renderDateOrSlash(project.lastExtensionApprovedDate)}</StyledTableCell>
                            <StyledTableCell>
                              <NumberFormat
                                value={formatedValueProperty('money', project.operationApprovedAmount)}
                                displayType={'text'}
                                thousandSeparator={','}
                                decimalSeparator={'.'}
                                suffix={isPercentageFieldType('money')}
                              />
                            </StyledTableCell>
                            <StyledTableCell>
                              <NumberFormat
                                value={formatedValueProperty('money', project.realizedDisbursementAmount)}
                                displayType={'text'}
                                thousandSeparator={','}
                                decimalSeparator={'.'}
                                suffix={isPercentageFieldType('money')}
                              />
                            </StyledTableCell>
                            <StyledTableCell>
                              <NumberFormat
                                value={formatedValueProperty('percentage', project.percentRealizedDisbursement)}
                                displayType={'text'}
                                thousandSeparator={','}
                                decimalSeparator={'.'}
                                suffix={isPercentageFieldType('percentage')}
                              />
                            </StyledTableCell>
                            <StyledTableCell>
                              <NumberFormat
                                value={formatedValueProperty('money', project.pendingDisbursementAmount)}
                                displayType={'text'}
                                thousandSeparator={','}
                                decimalSeparator={'.'}
                                suffix={isPercentageFieldType('money')}
                              />
                            </StyledTableCell>
                            <StyledTableCell>{t(project.overallStage)}</StyledTableCell>
                            <StyledTableCell>
                              <PmrClassificationComponent classificationColor={parseInt(project.pmrClassification)} />
                              <PmrPrintValue
                                pmrClassi={parseInt(project.pmrClassification)}
                                pmrValue={parseFloat(project.pmrValue)}
                              />
                            </StyledTableCell>
                          </>
                        )}
                        {/* {tableInfo.map(val => {
                        return (
                          <StyledTableCell key={val.tableHeader} style={{}} align={val.cellAlign}>
                            {val.customCellContent
                              ? renderCustomCellContent(val.customCellContent, project)
                              : val.fieldType
                              ? dataByFieldType(val, project)
                              : val.tableHeader === 'project' || val.tableHeader === 'name'
                              ? renderingColumnId(val.tableHeader, project)
                              : project[val.rootProperty]}
                          </StyledTableCell>
                        );
                      })} */}

                        {/* <StyledTableCell align="left" style={{ borderRadius: '0 6px  6px 0', border: 'none' }}>
                        <BidTooltip
                          title={tooltipTitle(t, 'project_info')}
                          placement="bottom-start"
                          enterDelay={500}
                          leaveDelay={200}
                        >
                          <span className="fw" />
                        </BidTooltip>
                      </StyledTableCell> */}
                      </StyledTableRow>
                    ))}
              </TableBody>
            </Table>
          </div>
        )}
      </TableContainer>

      <div className="pagination-style">
        <PaginationTable fnSetRows={setRowsPerPage} rows={rowsPerPage} numProyects={dataFilter?.length} />
        {dataFilter?.length !== 0 ? (
          <Pagination count={pagesCount} page={currentPage} onChange={handlePaginationChange} />
        ) : null}
      </div>
    </div>
  );
};

const dataByFieldType = (val, project) => {
  if (val.fieldType === 'date') {
    return renderDateOrSlash(project[val.rootProperty]);
  } else {
    return (
      <NumberFormat
        value={formatedValueProperty(val.fieldType, project[val.rootProperty])}
        displayType={'text'}
        thousandSeparator={','}
        decimalSeparator={'.'}
        suffix={isPercentageFieldType(val.fieldType)}
      />
    );
  }
};

export const customRowStyle = (index: number, projectId?: number | null) => {
  return index % 2
    ? { cursor: projectId ? 'pointer' : 'default', background: '#fafafa' }
    : { cursor: projectId ? 'pointer' : 'default', background: 'white' };
};

const renderCustomCellContent = (customCellContent, project) => {
  if (customCellContent.name === 'pmr') {
    return (
      <>
        <PmrClassificationComponent classificationColor={parseInt(project.field6)} />
        <span>
          <PmrPrintValue pmrClassi={parseInt(project.field6)} pmrValue={parseFloat(project.field5)} />
        </span>
      </>
    );
  } else if (customCellContent.name === 'pmr_and_tcdi') {
    return (
      <div className="flex-center">
        <TcdiClassificationComponent clasification={project.field5} />
        <span style={{ marginLeft: 2 }}>{project.field5}</span>
      </div>
    );
  }
  return null;
};
