enum PcrState {
  NA,
  OnTime,
  PendingApproval,
  PendindPublication,
  VeryHigh,
  High,
  Medium,
  UpcomingSubmisionGroup,
  LikelyToBeSubmittedShortTermGroup,
}

enum PcrNextPresentationChartEnum {
  UPCOMING_SUBMISSION,
  LIKELY_TO_BE_SUBMITTED_SHORT_TERM,
}

enum TranslationStatusKeysOnUpcomingSubmissionTable {
  'on_time' = 1,
  'vpc_mgr_approval_pending',
  'pending_publication_on_the_internet',
}

enum TranslationStatusKeysOnLikelyToBeSubmittedTable {
  'very_high' = 4,
  'high_pcr_next_presentation',
  'medium_pcr_next_presentation',
}

export interface PcrBullets {
  bulletColor: string;
  bulletText: string;
}
const upcomingSubmissionBulletsDefault: PcrBullets[] = [
  {
    bulletColor: '#B1CBFF',
    bulletText: 'on_time',
  },
  {
    bulletColor: '#538CFF',
    bulletText: 'vpc_mgr_approval_pending',
  },
  {
    bulletColor: '#004CE4',
    bulletText: 'pending_publication_on_the_internet',
  },
];
const likelyToBeSubmittedShortTermDefault: PcrBullets[] = [
  {
    bulletColor: '#BF1313',
    bulletText: 'very_high',
  },
  {
    bulletColor: '#E92C2C',
    bulletText: 'high_pcr_next_presentation',
  },
  {
    bulletColor: '#FCC0AA',
    bulletText: 'medium_pcr_next_presentation',
  },
];

enum PCRCategorizationValues {
  UPCOMING_SUBMISSION = '7',
  LIKELY_TO_BE_SUBMITTED_SHORT_TERM = '8',
}
export {
  PcrState,
  upcomingSubmissionBulletsDefault,
  likelyToBeSubmittedShortTermDefault,
  TranslationStatusKeysOnUpcomingSubmissionTable,
  TranslationStatusKeysOnLikelyToBeSubmittedTable,
  PcrNextPresentationChartEnum,
  PCRCategorizationValues,
};
