import React, { useEffect } from 'react';
import Icon from '@material-ui/core/Icon';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import { useTranslation } from 'react-i18next';

import './CardIndicator.scss';
import MoreIcon from '../../../assets/more.svg';
import { ReactComponent as ZoomIcon } from '../../../assets/ic_zoom.svg';
import { BarChart, RadialBarChart, TextIndicator, ProgressBarIndicator } from './Cards';
import Indicator from '../../../models/Indicator';
import CloseCrossButton from '../../Util/Buttons/CloseCrossButton/CloseCrossButton';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentIndicator } from '../../../redux/actions/currentIndicator';
import { translateMultipleText } from '../../../services/stringUtil';
import { isMobile } from '../../../services/booleanUtil';
import { removeIndicatorFromList } from '../../../redux/actions/indicators';
import { IndicatorKeyEnum } from '../../../models/IndicatorKeyEnum';
import { SummaryIndicator } from './Cards/SummaryIndicator/SummaryIndicator';
import { DoughnutChart } from './Cards/DoughnutChart/DoughnutChart';
import { ListIndicator } from './Cards/ListIndicator/ListIndicator';
import { CardTypesEnum } from '../../../models/CardTypesEnum';
import { CalendarIndicator } from './Cards/CalendarIndicator/CalendarIndicator';
import { trackActionClicked } from '../../../api/trackActionClickedApi';
import { TrackedActions } from '../../../models/TrackedActionsEnum';
import { MultiDataNotStackedBar } from './Cards/MultiDataNotStackedBar/MultiDataNotStackedBar';
import { filterDatasets } from '../../PortfolioDetail/ActualProjectedDifferenceDetail/util';
import { DoubleNumberWithTitle } from './Cards/DoubleNumberWithTitle/DoubleNumberWithTitle';
import ProgressChart from './Cards/ProgressChart/ProgressChart';
import { BubbleCardChart } from './Cards/BubbleCardChart/BubbleCardChart';
import { TextDoubleIndicator } from './Cards/TextDoubleIndicator/TextDoubleIndicator';
import { ReportImageDisplay } from '../../Util/Modals/CustomizableReports/CustomizableReports';
import { mixedVerticalOptions } from '../IndicatorsHelpers/mixedchartsIndicatorsHelper';
import { skyBlueOrangeVerticalOptions } from '../IndicatorsHelpers/barchartsIndicatorsHelper';
import { MultiLine } from './Cards/MultiLine/MultiLine';
import { multiLineOptions } from '../IndicatorsHelpers/multiLineIndicatorHelper';
import { EarlyWarningsIcon } from './EarlyWarningsIcon/EarlyWarningsIcon';
import { ReactComponent as UDraganddrop } from '../../../assets/icons/u_draganddrop.svg';
import { ReactComponent as ULocationUnpin } from '../../../assets/icons/u_location-unpin.svg';
import { ReactComponent as UArrowRight } from '../../../assets/icons/u_arrow-right.svg';
import { ReactComponent as ULocationPin } from '../../../assets/icons/u_location-pin.svg';
import Grid from '@material-ui/core/Grid';
import {
  monthlyDisbursementProjectionCurrentYearIncludingRegionalsOptions,
  offsetBarPlugin,
} from '../IndicatorsHelpers/addDisbProjectMonthlyUtil';
import { ReactComponent as IconPin } from '../../../assets/icons/u_location-unpin.svg';
import { addIndicatorToUserIndicatorCollection, getUserIndicatorCollection, removeIndicatorFromUserIndicatorCollection } from '../../../api/indicatorApi';
import { setUserIndicatorCollection } from '../../../redux/actions/userIndicatorCollection';
const barChartOptions = (cardType: CardTypesEnum) => {
  if (cardType === CardTypesEnum.MIXED_VERTICAL_BAR_LINE) {
    return mixedVerticalOptions;
  } else if (cardType === CardTypesEnum.VERTICAL_SKYBLUE_ORANGE_BAR) {
    return skyBlueOrangeVerticalOptions;
  } else {
    return undefined;
  }
};

const colorForReport = 'transparent';

export const isReportDetailImg = forReport => {
  return forReport && forReport === ReportImageDisplay.Detail;
};

export const backgroundColorCustomStyle = forReport => {
  if (!forReport) {
    return {};
  } else {
    const bgColor = forReport === ReportImageDisplay.Detail ? '#1F3864' : 'white';
    return { backgroundColor: bgColor, borderRadius: '0px' };
  }
};

const fallbackData = card => (card.data && card.data.progressData ? card.data.progressData : null);
const fallbackDoubleProgressChartData = card => {
  if (card.data && card.data.firstDataGroup && card.upcomingSubmissionTotal !== 0) {
    return card.data.firstDataGroup;
  } else if (card.data && card.data.secondDataGroup && card.likelyToBeSubmittedShortTermTotal !== 0) {
    return card.data.secondDataGroup;
  }
  return null;
};

const fallbackId = (reportWordKey = '', card: Indicator, forReport?: ReportImageDisplay) =>
  forReport ? `${reportWordKey || card.indicatorKey}` : '';

const titleClassMap = {
  [CardTypesEnum.DOUGHNUT]: 'text-container-doughunt',
  [CardTypesEnum.CALENDAR]: 'text-container-calendar',
  [CardTypesEnum.DOUBLE_DATA_NOT_STACKED]: 'text-container-double-data-not-stacked',
  [CardTypesEnum.RADIAL_TO_BARCHART]: 'text-container-radial-to-barchart',
};

const dataContainerClassMap = {
  [CardTypesEnum.SUMMARY]: 'summary-container',
  [CardTypesEnum.TEXT_DOUBLE]: 'summary-container',
  [CardTypesEnum.DOUGHNUT]: 'chart-doughnut-container',
  [CardTypesEnum.LIST]: 'list-container',
  [CardTypesEnum.HALF_SIZE_VERTICAL]: 'half-chart-container',
  [CardTypesEnum.CALENDAR]: 'calendar-container',
  [CardTypesEnum.DOUBLE_DATA_NOT_STACKED]: 'double-data-not-stacked-container',
  [CardTypesEnum.RADIAL_TO_BARCHART]: 'radial-to-barchart-container',
  [CardTypesEnum.BUBBLE_CARD_TYPE]: 'bubble-card-chart-container',
  [CardTypesEnum.MULTI_LINE]: 'multi-line-container',
};

type Props = {
  card: Indicator;
  forReport?: ReportImageDisplay;
  reportWordKey?: string;
  classSetter: boolean;
};

const popoverStyles = {
  backgroundColor: '#f6f7f9',
  height: '56px',
  width: '166px',
  borderRadius: '10px',
  display: 'flex',
  fontFamily: 'Rubik',
  color: '#727E8C',
  fontSize: '14px',
  alignItems: 'center',
  paddingLeft: '10px',
  boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.23)',
  zIndex: 3,
};

const CardIndicator = (props: Props) => {
  const { card, forReport, reportWordKey, classSetter } = props;
  const dispatch = useDispatch();
  const indicators = useSelector<any, any>(state => state.indicators);
  const userIndicatorCollection = useSelector<any, any>(state => state.userIndicatorCollection.userIndicatorCollection);

  const userIndicatorCodesSet = new Set(userIndicatorCollection.map(item => item.code));
  const isPinned = userIndicatorCodesSet.has(card.indicatorKey);


  const [hover] = React.useState<boolean>(false);
  const [isPopoverOpen, setIsPopoverOpen] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const history = useHistory();
  const listPage = true;
  const filterIsActivity = localStorage.getItem('activities') === 'true';

  const onClickCard = () => {
    card.data !== null && onClickMore();
  };

  const onClickMore = () => {
    dispatch(setCurrentIndicator(card));
    history.push(`/portfoliodetail`);
    trackActionClicked(TrackedActions.indicatorDetailsClicked, `[${card.id}]`);
  };

  const renderCard = (forReport: ReportImageDisplay | undefined) => {
    switch (card.type) {
      case CardTypesEnum.HORIZONTAL:
      case CardTypesEnum.VERTICAL:
      case CardTypesEnum.HALF_SIZE_VERTICAL:
      case CardTypesEnum.MIXED_VERTICAL_BAR_LINE:
      case CardTypesEnum.VERTICAL_SKYBLUE_ORANGE_BAR:
        return (
          <BarChart
            type={card.type}
            data={barChartData(card, t, forReport)}
            showSubLabels={!isMobile()}
            listPage={listPage}
            datasetClick={() => { }}
            indicatorKey={card.indicatorKey}
            forReport={forReport}
            chartOptions={barChartOptions(card.type)}
          />
        );
      case CardTypesEnum.RADIAL:
      case CardTypesEnum.RADIAL_TO_BARCHART:
        return (
          <RadialBarChart data={card.data} listPage={listPage} indicatorKey={card.indicatorKey} forReport={forReport} />
        );
      case CardTypesEnum.TEXT:
        return <TextIndicator card={card} />;
      case CardTypesEnum.DOUGHNUT:
        return (
          <DoughnutChart
            card={doughnutDataForReport(card, forReport)}
            customStyle={{ flexFlowStyle: 'column' }}
            forReport={forReport}
          />
        );
      case CardTypesEnum.PROGRESS:
        return <ProgressBarIndicator data={card.data} />;
      case CardTypesEnum.LIST:
        return <ListIndicator data={card.data} />;
      case CardTypesEnum.CALENDAR:
        return <CalendarIndicator data={card.data} />;
      case CardTypesEnum.DOUBLE_DATA_NOT_STACKED:
        return (
          <MultiDataNotStackedBar
            data={barChartData(filterDatasets(card, 'data', filterIsActivity), t, forReport)}
            customDimensions={{ desktop: { width: 270, height: 200 }, mobile: { width: 110, height: 80 } }}
            forReport={forReport}
          />
        );
      case CardTypesEnum.PROGRESS_CHART:
        return <ProgressChart data={fallbackData(card)} indicatorKey={card.indicatorKey} forReport={forReport} />;
      case CardTypesEnum.DOUBLE_DATA_PROGRESS_CHART:
        return (
          <ProgressChart
            data={fallbackDoubleProgressChartData(card)}
            indicatorKey={card.indicatorKey}
            forReport={forReport}
          />
        );
      case CardTypesEnum.SIMPLE_SUMMARY:
        return <DoubleNumberWithTitle card={card} />;
      case CardTypesEnum.BUBBLE_CARD_TYPE:
        return <BubbleCardChart data={card.data} forReport={forReport} />;
      case CardTypesEnum.TEXT_DOUBLE:
        return <TextDoubleIndicator data={card.data} forReport={forReport} />;
      case CardTypesEnum.MULTI_LINE:
        return (
          <MultiLine
            data={getFirstIndexFromCardData(card)}
            customDimensions={{ desktop: { width: 140, height: 90 }, mobile: { width: 70, height: 45 } }}
            options={multiLineOptions(forReport)}
          />
        );
      case CardTypesEnum.DOUBLE_DATA_NOT_STACKED_MOUNTED_BARS:
        return (
          <MultiDataNotStackedBar
            data={barChartData(card, t, forReport)}
            customDimensions={{ desktop: { width: 270, height: 200 }, mobile: { width: 110, height: 80 } }}
            forReport={forReport}
            customOption={monthlyDisbursementProjectionCurrentYearIncludingRegionalsOptions(
              t,
              getVerticalLineValue(card),
            )}
            plugins={[offsetBarPlugin]}
          />
        );
      default:
        return null;
    }
  };

  const removeIndicator = () => {
    dispatch(removeIndicatorFromList(card.id));
    setIsPopoverOpen(!isPopoverOpen);
    trackActionClicked(TrackedActions.indicatorRemoved);
  };

  const handleAddIndicator = async () => {
    try {
      await addIndicatorToUserIndicatorCollection(card.id); // ?? ENDPOINT GMV
      const updatedCollection = [...userIndicatorCollection, { code: card.indicatorKey }];
      dispatch(setUserIndicatorCollection(updatedCollection));
      // trackActionClicked(TrackedActions.indicatorAdded);
    } catch (error) {
      console.error('Error adding indicator to collection:', error);
    }
  };

  const handleRemoveIndicator = async () => {
    try {
      if (classSetter) {
        await removeIndicatorFromUserIndicatorCollection(card.id);
      } else {
        removeIndicator()
      }
      const updatedCollection = userIndicatorCollection.filter(item => item.code !== card.indicatorKey);
      dispatch(setUserIndicatorCollection(updatedCollection));

      trackActionClicked(TrackedActions.indicatorRemoved);
    } catch (error) {
      console.error('Error removing indicator from collection:', error);
    }
  };

  return (
    <div className='card-indicator-container'>
      {indicators.length > 1 && (

        <div className='pin'>
          {isPinned
            ? <div onClick={handleRemoveIndicator}><ULocationUnpin /></div>
            : <div className="pin-up" onClick={handleAddIndicator}><ULocationPin /> </div>}
        </div>

      )}
      <div
        className="card-section"
        onClick={onClickCard}
        id={fallbackId(reportWordKey, card, forReport)}
        style={backgroundColorCustomStyle(forReport)}
      >
        {forReport ? null : (
          <>
            <div className="header">
              <div className="drag-and-drop">
                <UDraganddrop />
                {forReport ? null : <EarlyWarningsIcon indicatorKey={card.indicatorKey} />}
              </div>
            </div>
          </>
        )}
        {card.type !== 'text' && card.title && (
          <div
            onClick={onClickCard}
            className={`${setTitleClassName(card.type)}  ${card.indicatorKey === 'ACQUISITION_WITH_MILESTONES_PENDING_TO_DATE' ? 'custom-height-hitos' : ''} `}
            style={isReportDetailImg(forReport) || reportWordKey ? { visibility: 'hidden' } : {}}
          >
            <button className="card-indicator__title" onClick={onClickMore} tabIndex={30}>
              {translateMultipleText(card.title, t)}
            </button>
            {card.subtitle && (
              <span className={`subtitle ${customSubtitleStyle(card.indicatorKey)}`}>
                {translateMultipleText(card.subtitle, t)}
              </span>
            )}
          </div>
        )}
        <div className={setDataContainerClassName(card.type)}>{renderCard(forReport)}</div>
        <div className="footer" onClick={onClickCard}>
          {card.data !== null && CardHaveData(onClickMore)}
        </div>
      </div>
    </div>
  );
};

export default CardIndicator;

const customSubtitleStyle = (indicatorKey?: string) => {
  return indicatorKey && indicatorKey === IndicatorKeyEnum.AVERAGE_LAG ? 'nocapital' : '';
};

const setTitleClassName = cardType => titleClassMap[cardType] || 'text-container';
const setDataContainerClassName = cardType => dataContainerClassMap[cardType] || 'chart-container';


export const CardHaveData = onClickMore => {
  const { t } = useTranslation();
  return (
    <button className="btn-primary-text" onClick={onClickMore}>
      {t('see_more')} <UArrowRight />
    </button>
  );
};

export const updateDatasetColorBar = card => {
  const dat = [...card.data.datasets];
  const maxDivisionsOrCountriesValues = 8;
  return dat.map(set => {
    if (
      (set.data.length >= maxDivisionsOrCountriesValues && IndicatorKeyEnum.PMR_PERFORMANCE_LAST_CLOSED_YEAR) ||
      (set.data.length >= maxDivisionsOrCountriesValues &&
        IndicatorKeyEnum.PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_DIVISION) ||
      (set.data.length >= maxDivisionsOrCountriesValues &&
        IndicatorKeyEnum.PORTFOLIO_OVERVIEW_INCLUDING_REGIONAL_PROJECTS_BY_COUNTRY)
    ) {
      const newBackgroundColor = [...set.backgroundColor];
      newBackgroundColor.splice(5, 1, colorsWithOpacity(set.label).penultimateBackgroundColor);
      newBackgroundColor.splice(6, 1, colorsWithOpacity(set.label).lastBackgroundColor);
      set = { ...set, backgroundColor: newBackgroundColor };
    }

    return set;
  });
};

export const colorsWithOpacity = label => {
  let penultimateBackgroundColor = '';
  let lastBackgroundColor = '';

  switch (label) {
    case 'problemChartData':
      penultimateBackgroundColor = 'rgba(240, 109, 109, 0.5)';
      lastBackgroundColor = 'rgba(240, 109, 109, 0.2)';
      break;
    case 'alertChartdata':
      penultimateBackgroundColor = 'rgba(247, 221, 0, 0.5)';
      lastBackgroundColor = 'rgba(247, 221, 0, 0.2)';
      break;
    case 'satisChartData':
      penultimateBackgroundColor = 'rgba(145, 226, 161, 0.5)';
      lastBackgroundColor = 'rgba(145, 226, 161, 0.2)';
      break;
    case 'naChartData':
      penultimateBackgroundColor = 'rgba(215, 219, 228, 0.5)';
      lastBackgroundColor = 'rgba(215, 219, 228, 0.2)';
      break;
    case 'lonChartData':
      penultimateBackgroundColor = 'rgba(177, 148, 190, 0.5)';
      lastBackgroundColor = 'rgba(177, 148, 190, 0.2)';
      break;
    case 'tcpChartData':
      penultimateBackgroundColor = 'rgba(148, 182, 182, 0.5)';
      lastBackgroundColor = 'rgba(148, 182, 182, 0.2)';
      break;
    case 'igrChartData':
      penultimateBackgroundColor = 'rgba(249, 194, 104, 0.5)';
      lastBackgroundColor = 'rgba(249, 194, 104, 0.2)';
      break;

    default:
      penultimateBackgroundColor = '';
      lastBackgroundColor = '';
      break;
  }

  return { penultimateBackgroundColor, lastBackgroundColor };
};

const fallbackDataSets = (card, forReport) =>
  card.data ? (forReport ? [...card.data.datasets] : updateDatasetColorBar(card)) : [];
const fallbackLabels = (card, minDivisionsValues, maxDivisionsValues) =>
  card.data ? card.data.labels.slice(minDivisionsValues, maxDivisionsValues) : [];
const fallbackSubLabels = (card, minDivisionsValues, maxDivisionsValues) =>
  card.data ? card.data.subLabels.slice(minDivisionsValues, maxDivisionsValues) : [];

export const barChartData = (card, t, forReport) => {
  const minDivisionsValues = 0;
  const maxDivisionsValues = 7;

  if (!card) {
    return [];
  }

  return card.translateChartLabels
    ? {
      ...card.data,
      labels: card.data.labels.map(label => {
        return t(label);
      }),
    }
    : card.type === CardTypesEnum.HORIZONTAL
      ? {
        ...card.data,
        datasets: fallbackDataSets(card, forReport),
        labels: fallbackLabels(card, minDivisionsValues, maxDivisionsValues),
        subLabels: fallbackSubLabels(card, minDivisionsValues, maxDivisionsValues),
      }
      : card.data;
};

const doughnutDataForReport = (card, forReport) => {
  if (isReportDetailImg(forReport)) {
    return {
      ...card,
      data: card.data
        ? {
          ...card.data,
          datasets: card.data.datasets.map(d => {
            d.borderColor = colorForReport;
            return d;
          }),
        }
        : null,
    };
  }
  return card;
};

const getFirstIndexFromCardData = card => {
  return card.data && card.data[0];
};
const getVerticalLineValue = card => {
  return card && card.yearNumber && card.yearNumber.findIndex((year: any) => year === new Date().getFullYear() + 1);
};
