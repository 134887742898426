import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './TableHeader.module.scss';

const { tableHeader, initials, title } = styles;

type Props = {
  titleKey: string;
};
export const TableHeader = ({ titleKey }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={tableHeader}>
      <p className={initials}>{t('pcr_initials')}</p>
      <p className={title}>{t(titleKey)}</p>
    </div>
  );
};
